import freud from '../images/icons/freud.png';
import logoblack from '../images/icons/logoblack.png';
import logowhite from '../images/icons/logowhite.png';

export const light = {
  theme: 'light',
  border: '#250A0D9f',
  font1: '#b5874b', // Nova cor para segunda fonte
  font2: '#250A0D', // Nova cor para primeira fonte
  font3: '#4b161c', // Mantendo a cor da terceira fonte
  background1: '#f4efe0', // Nova cor para o primeiro fundo
  background2: '#FCEBB8', // Nova cor para o segundo fundo
  moon: freud,
  logo: logoblack,
};

export const dark = {
  theme: 'dark',
  border: '#b5874b9f',
  font1: '#b5874b', // Nova cor para primeira fonte
  font2: '#f4efe0', // Nova cor para segunda fonte
  font3: '#250A0D', // Mantendo a cor da terceira fonte
  background1: '#4b161c', // Nova cor para o primeiro fundo
  background2: '#250A0D', // Nova cor para o segundo fundo
  moon: freud,
  logo: logowhite,
};

// #4b161c
// #f4efe0
