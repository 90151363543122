import React from 'react';
import whatsapp from '../../images/icons/whatsapp.svg';

function Whatsapp({ param = 'https://wa.me/+553198533003' }: any) {
  return (
    <a href={param} target="_blank" className="ext" rel="noreferrer">
      <img src={whatsapp} alt="whatsapp logo" />
    </a>
  );
}

export default Whatsapp;
