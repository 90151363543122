import styled from 'styled-components';

export const Main = styled.div`
  margin-top: 100px;
  margin-bottom: 150px;

  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  font-size: 15px;
  height: 100%;
  color: ${({ theme }) => theme.font2};
  p img {
    width: 50px;
  }
  // height: 80vh;
  @media (max-width: 1400px) {
    margin-top: 100px;
    margin-bottom: 120px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    height: 100%;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    height: 90%;
  }
  background-color: ${({ theme }) => theme.background1};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  // height: 90vh;
  flex-wrap: wrap;
  gap: 2rem; /* Espaçamento entre os cards */
  justify-content: flex-start;
  max-width: 1200px; /* Limita a largura máxima */
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  @media (max-width: 500px) {
    width: 200px;
  }
  @media (max-width: 1000px) {
    width: 400px;
  }
  @media (max-width: 800px) {
    width: 300px;
  }
  text-align: center;
  gap: 1rem;
  text-align: justify;
`;

export const EventCard = styled.div`
  background-color: ${({ theme }) =>
    theme.background2}; /* Adapte para a cor desejada */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  a {
    color: ${({ theme }) => theme.font1};
  }
  a: hover {
    color: ${({ theme }) => theme.font2}};
  }

  width: 100%; /* Largura do card */

  
`;
