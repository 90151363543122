import React from 'react';
import * as S from './styles';
import Events from '../Events';
function Home() {
  return (
    <S.Main>
      <S.Section>
        <S.Logo />
        <S.TextDiv>
          <p>
            Somos um grupo de psicanalistas em Belo Horizonte que tem por
            objetivo estabelecer uma rede de formação contínua em psicanálise
            (teoria, supervisão e clínica).
          </p>
          <p>
            Oferecemos palestras, seminários, minicursos, grupos de estudos e
            supervisões. Além disso, estamos construindo uma rede de clínicas
            sociais para que a população mais carente da nossa cidade tenha
            acesso a espaços de tratamento para seu sofrimento psíquico.
          </p>
        </S.TextDiv>
        <S.TextHorizontal>
          <div>
            <h1>Atendimento clínico</h1>
            <p>
              Com profissionais bem-indicados e adequados ao perfil de cada
              paciente.
            </p>
          </div>
          <div>
            <h1>Formação continuada </h1>
            <p>
              Por meio de palestras, seminários, grupos de estudos e clubes de
              leitura.
            </p>
          </div>
          <div>
            <h1>Diversidade teórica, prática e clínica </h1>
            <p>
              Formação plural é um de nossos principais valores, por isso
              incentivamos o estudo de uma psicanálise plural em vez de
              centralizarmos nossa formação em um único autor ou em uma única
              contribuição teórica.
            </p>
          </div>
        </S.TextHorizontal>
        <Events />
      </S.Section>
    </S.Main>
  );
}

export default Home;
