import React from 'react';
import instagram from '../../images/icons/instagram.svg';

function Instagram() {
  return (
    <a
      href="https://www.instagram.com/travessias.psicanalise/"
      target="_blank"
      className="ext"
      rel="noreferrer"
    >
      <img src={instagram} alt="instagram logo" />
    </a>
  );
}

export default Instagram;
